<!--
 * @Author: your name
 * @Date: 2020-04-23 16:12:30
 * @LastEditTime: 2020-12-03 09:57:28
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /cunw-youzhiduo-OS/src/views/useHelp/index.vue
-->

<template>
  <div class="preview-news">
    <div class="main">
      <div class="left">
        <h3>中共中央政治局常委、国务院副总理韩正来我公司视察</h3>
        <div class="news-setting">
          <h3 class="time">
            发布时间： 2020-06-01
          </h3>
          <!-- <h5 class="num">
            浏览量： 28289
          </h5> -->
        </div>
        <div class=" img-box" style="margin-top:40px">
          <img src="../../assets/images/os/aboutUs1.png" alt="">
        </div>
        <div class="news-content">
          <p style="text-align:justify;text-indent: 36px;">
            2020 年 6 月 1 日， 中共中央政治局常委、国务院副总理韩正来到湖南新云网科技有限公司视察，了解透明计算成果转化情况，对我司提出的“ 建设一座没有围墙的学校”的理念和做法，对高校创新合作模式以及取得的成果表示肯定。并对我司以“ 透明计算”技术为基础，自主创新，
            融合 5G、大数据、人工智能、物联网等先进技术， 打造的智慧教育生态体系给予了高度评价。他强调，要充分发挥高校科研优势，促进产学研更好结合，构建技术研发、成果孵化、实现产业化的科技创新链条。
          </p>
        </div>
        <div class="pagination">
          <div class="a-more">
            <span @click="$router.push('/indexOs/newsDetails15')">上一条：打通高校科研与市场“隔断” 服务社会高质量发展</span>
            <span @click="$router.push('/indexOs/newsDetails2')">下一条：岳麓山大学科技城：强化科技人才支撑 打造湖南创新高地</span>
          </div>
          <el-button plain @click="backGo()">
            返回
          </el-button>
        </div>
      </div>
      <!-- 搜索区域 -->
      <!-- <div class="search-right">
        <el-input
          v-model="keyWord"
          placeholder="查询你喜欢的内容"
          suffix-icon="el-icon-search"
        />
        <div class="hot-news">
          <div class="hot-title">
            <span>最热文章</span>
            <i class="hot-icon" />
          </div>
          <div class="lb-news">
            <router-link class="dg-news" to="/indexOs/newsDetails1">
              <img src="../../assets/images/news/news2-1.png">
              <div class="news-introduce">
                <h3 class="news-title">
                  中共中央政治局常委、国务院副总理 韩正来我公司视察
                </h3>
                <h5 class="time">
                 2020-06-01
                </h5>
              </div>
            </router-link>
             <router-link class="dg-news" to="/indexOs/newsDetails2">
              <img src="../../assets/images/news/news2-2.png">
              <div class="news-introduce">
                <h3 class="news-title">
                    岳麓山大学科技城：强化科技人才支撑 打造湖南创新...
                </h3>
                <h5 class="time">
                  2020-06-03
                </h5>
              </div>
            </router-link>
            <router-link class="dg-news" to="/indexOs/newsDetails3">
              <img src="../../assets/images/news/news2-3.png">
              <div class="news-introduce">
                <h3 class="news-title">
                  大科城软件产业联盟拍了拍
                </h3>
                <h5 class="time">
                  2020-08-03
                </h5>
              </div>
            </router-link>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'PreviewNews',
  data() {
    return {
      keyWord: ''
    };
  },
  methods: {
    backGo() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1400px) {
  .preview-news{
    width: 100%;
    height: auto;
    background: #F5F5F5;
    .main{
      width: 800px;
      height: auto;
      margin: 0 auto;
      display: flex;
      padding-top: 58px;
      justify-content: space-between;
      .left{
        flex: 1;
        margin-right: 63px;
        h3{
          color: #313131;
          font-size:24px;
          font-weight: bolder;
          text-align: center;
        }
        .news-setting{
          color: #B5B5B5;
          display: flex;
          font-size:18px;
          .time{
            font-size:18px;
            color: #B5B5B5;
            font-weight: 100;
            width: 100%;
            text-align: center;
          }
          .num{
            font-size:18px;
            margin-left: 30px;
            font-weight: 100;
          }
        }
        .news-content{
          margin-top: 20px;
          height: auto;
          margin-bottom: 40px;
          p{
            font-size: 18px;
            color: #313131;
            line-height: 32px;
          }
          .img-explain{
            text-align:justify;
            text-align: center;
            font-size:16px;
          }
        }
        .img-box{
          width: 100%;
      display: flex;
      justify-content: center;
          img{
            width:800px;
          }
        }
      }
      .pagination{
        display: flex;
        justify-content: space-between;
        padding: 50px 0 150px;
        border-top: 1px solid #DDDDDD;
        .a-more{
          display: flex;
          flex-direction: column;
          font-size:18px;
          line-height:32px;
          color:rgba(49,49,49,1);
          span{
            display: inline-block;
            width:600px;
            white-space: nowrap;
            text-overflow:ellipsis;
            overflow: hidden;
            cursor: pointer;
          }
        }
        .el-button{
          width: 134px;
          height: 48px;
          border: 1px solid rgba(253,156,2,1);
          opacity: 1;
          border-radius: 10px;
          font-size:24px;
          color:rgba(253,156,2,1);
        }
      }
      // 搜索区域
      .search-right{
        width: 334px;
        .el-input{
          width: 329px;
          height: 45px;
        }
        .el-input__inner{
          border-radius: 5px;
        }
        .el-input__icon{
          font-size: 24px;
        }
        .hot-news{
          .hot-title{
            font-size:24px;
            margin-bottom: 12px;
            color:#313131;
            .hot-icon{
              display: inline-block;
              background: url('../../assets/images/news/hot.png');
              width: 18px;
              height: 22px;
              margin-left: 6px;
            }
          }
          .dg-news{
            display: flex;
            margin-bottom: 22px;
            img{
              width:132px;
              height:76px;
            }
            .news-introduce{
              flex: 1;
              margin-left: 8px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              .news-title{
                font-size:16px;
                font-weight: 600;
                color: #313131;
                box-sizing: border-box;
                text-overflow:ellipsis;
                display:-webkit-box;
                -webkit-box-orient:vertical;
                -webkit-line-clamp:2;
                overflow: hidden;
              }
              .time{
                font-size:18px;
                color: #B5B5B5;
                font-weight: 100;
              }
            }
            &:hover{
              .news-title{
                color:rgba(253,156,2,1);
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1400px) {
  .preview-news{
    width: 100%;
    height: auto;
    background: #fff;
    .main{
      width: 100%;
      height: auto;
      display: flex;
      padding-top: 1rem;
      justify-content: space-between;
      padding:1.07rem .48rem;
      .left{
        flex: 1;
        h3{
          color: #313131;
          font-size:.48rem;
          font-weight: bolder;
          text-align: center;
        }
        .news-setting{
          color: #B5B5B5;
          display: flex;
          font-size:18px;
          .time{
            font-size:.267rem;
            color: #B5B5B5;
            font-weight: 100;
            width: 100%;
            text-align: center;
          }
          .num{
            font-size:18px;
            margin-left: 30px;
            font-weight: 100;
          }
        }
        .news-content{
          width: 100%;
          p{
            font-size: .373rem;
            color: #313131;
            line-height: .7rem;
            text-indent: .747rem;
          }
          .img-explain{
            text-align:justify;
            text-align: center;
            font-size:.267rem;
          }
        }
        .img-box{
          width: 100%;
          display: flex;
          justify-content: center;
          img{
            width:100%;
            height: 100%;
          }
        }
      }
      .pagination{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: .53rem;
        .a-more{
          display: flex;
          flex-direction: column;
          font-size:.32rem;
          span{
            display: inline-block;
            width:8.933rem;
            cursor: pointer;
            color:rgba(49,49,49,1);
            height: auto;
            padding:.267rem .32rem;
            border: 1px solid #8A8684;
            border-radius: .267rem;
            white-space: nowrap;
            text-overflow:ellipsis;
            overflow: hidden;
            margin-bottom: .48rem;
          }
        }
        .el-button{
          width: 100%;
          font-size:.427rem;
          border: 1px solid rgba(253,156,2,1);
          padding:.267rem .32rem;
          border-radius: .267rem;
          color:rgba(253,156,2,1);
        }
      }
      // 搜索区域
      // .search-right{
      //   width: 334px;
      //   .el-input{
      //     width: 329px;
      //     height: 45px;
      //   }
      //   .el-input__inner{
      //     border-radius: 5px;
      //   }
      //   .el-input__icon{
      //     font-size: 24px;
      //   }
      //   .hot-news{
      //     .hot-title{
      //       font-size:24px;
      //       margin-bottom: 12px;
      //       color:#313131;
      //       .hot-icon{
      //         display: inline-block;
      //         background: url('../../assets/images/news/hot.png');
      //         width: 18px;
      //         height: 22px;
      //         margin-left: 6px;
      //       }
      //     }
      //     .dg-news{
      //       display: flex;
      //       margin-bottom: 22px;
      //       img{
      //         width:132px;
      //         height:76px;
      //       }
      //       .news-introduce{
      //         flex: 1;
      //         margin-left: 8px;
      //         display: flex;
      //         flex-direction: column;
      //         justify-content: space-between;

      //         .news-title{
      //           font-size:16px;
      //           font-weight: 600;
      //           color: #313131;
      //           box-sizing: border-box;
      //           text-overflow:ellipsis;
      //           display:-webkit-box;
      //           -webkit-box-orient:vertical;
      //           -webkit-line-clamp:2;
      //           overflow: hidden;
      //         }
      //         .time{
      //           font-size:18px;
      //           color: #B5B5B5;
      //           font-weight: 100;
      //         }
      //       }
      //       &:hover{
      //         .news-title{
      //           color:rgba(253,156,2,1);
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }
}
</style>
